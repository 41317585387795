import React from 'react'
import { graphql } from 'gatsby'
import { Grid, makeStyles, useTheme, Card, CardActions, Button, CardContent, Typography, Box } from '@material-ui/core'

import Layout from '../components/layout'

const pokemongoUri = 'https://pokemongolive.com'

const useStyles = makeStyles(theme => ({
  card: {
    height: 180,
    display: 'flex',
    flexDirection: 'column',
  },
  card_content: {
    flex: 1,
    overflow: 'hidden',
  },
  item_action: {
    marginLeft: 'auto'
  }
}))

const IndexPage = ({ data }) => {
  const theme = useTheme()
  const classes = useStyles(theme);

  return (
    <Layout>
      <Box mt={4}>
        <Typography variant="h3" gutterBottom component="h1">Mises à jour</Typography>
        <Grid container spacing={3}>
          {data.pokemongo.articles.map((article, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={article.title}>
              <Card className={classes.card}>
                <CardContent className={classes.card_content}>
                  <Typography color="secondary" variant="caption">
                    {article.date}
                  </Typography>
                  <Typography>
                    {article.title}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    component="a"
                    href={`${pokemongoUri}${article.link}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.item_action}
                  >
                    Voir plus
                    </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query {
    pokemongo {
      articles {
        title
        link
        date
      }
    }
  }
`;

export default IndexPage
